// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

require("stylesheets/application.scss");

import FormValidation from "../shared/form_validation"
import axios from 'axios'


// Set SweetAlert2 in the global vue object with some defaults
import VueSweetalert2 from 'vue-sweetalert2';
const swal_options = {
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    confirmButtonColor: '#2178ff'
}
Vue.use(VueSweetalert2, swal_options);

// Customisable modal

import VModal from 'vue-js-modal';
Vue.use(VModal);

// Debounce plugin for searching ajax calls
import VueDebounce from 'vue-debounce';
Vue.use(VueDebounce);

// global pagination component
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate);

// global toggle component
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)


// global image cropper
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
Vue.use(Croppa);

// vue-moment
import Moment from 'vue-moment'
Vue.use(Moment);

// Infinite Scroll
import InfiniteLoading from 'vue-infinite-loading';
Vue.use(InfiniteLoading);

// Simple loading svg to show when making ajax calls
import { SyncLoader } from '@saeris/vue-spinners'
Vue.component('loader', SyncLoader)


import DatePicker from 'vue2-datepicker'
Vue.use(DatePicker)

import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
Vue.use(Datetime)

import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox);


// form wizard

import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, {
    plyr: {}
})

//ckeditor for articles

// import CKEditor from '@ckeditor/ckeditor5-vue2';
// Vue.use( CKEditor );

// import Editor from '@tinymce/tinymce-vue'
// Vue.use(Editor);

import VueApexCharts from 'vue-apexcharts'
import ApexCharts from 'apexcharts'
Vue.use(VueApexCharts)



//Import Froala Editor 
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)
Vue.config.productionTip = false

// For development purposes

// Vue.config.devtools = true


window.addEventListener('click', function(event) {
    if (document.getElementById('site_navigation') && event.target.parentNode.id != 'user_settings_reference') {
        if (document.getElementById('user_settings_content') && event.target.id != "account_menu_close") {
            if (event.target.closest('#user_settings_content') && event.target.closest('#user_settings_content').id == 'user_settings_content') {
                document.getElementById('user_settings_content').classList.remove('hidden');
            } else {
                document.getElementById('user_settings_content').classList.add('hidden');
            }
        } else if (event.target.id == "account_menu_close") {
            document.getElementById('user_settings_content').classList.add('hidden');
        }
    }
    if (document.getElementById('site_navigation') && event.target.parentNode.id != 'user_settings_reference_new') {
        if (document.getElementById('user_settings_content_new') && event.target.id != "account_menu_close") {
            if (event.target.closest('#user_settings_content_new') && event.target.closest('#user_settings_content').id == 'user_settings_content_new') {
                document.getElementById('user_settings_content_new').classList.remove('hidden');
            } else {
                document.getElementById('user_settings_content_new').classList.add('hidden');
            }
        } else if (event.target.id == "account_menu_close") {
            document.getElementById('user_settings_content_new').classList.add('hidden');
        }
    }
});

function initNavigationBar() {
    if (document.getElementById('site_navigation')) {
        document.getElementById('toggle_menu').addEventListener('click', function() {
            document.getElementById('menu_content').classList.toggle('hidden');
        });

        if (document.getElementById('user_settings_reference')) {
            document.getElementById('user_settings_reference').addEventListener('click', function() {
                document.getElementById('user_settings_content').classList.toggle('hidden');
            });
        }

        if (document.getElementById('user_settings_reference_new')) {
            document.getElementById('user_settings_reference_new').addEventListener('click', function() {
                document.getElementById('user_settings_content_new').classList.toggle('hidden');
            });
        }
    }
};



document.addEventListener('DOMContentLoaded', () => {
    initNavigationBar();
    FormValidation.init();
});

// window.onbeforeunload = function() {
//   sessionStorage.removeItem('alreadyShow');
// };

Vue.prototype.Window = window;